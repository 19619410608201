import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, StaticQuery, graphql } from "gatsby"
import $ from "jquery"

import ShapeFaq from "../components/shapes/ShapeFaq"

const FaqPage = ({ faqFields }) => {
  const faq = faqFields.edges[0].node.acf

  useEffect(() => {
    function randLetter() {
      var letters = ["a", "4"]
      var letter = letters[Math.floor(Math.random() * letters.length)]
      return letter
    }
    setInterval(function() {
      $(".page_header span").html(randLetter())
    }, 500)

    $(".faq_question strong").click(function() {
      if (
        !$(this)
          .parent()
          .hasClass("open")
      ) {
        $(".faq_question.open strong")
          .next()
          .slideUp()
        $(".faq_question.open").removeClass("open")
      }
      $(this)
        .next()
        .slideToggle()
      $(this)
        .parent()
        .toggleClass("open")
    })
  })

  return (
    <Layout>
      <SEO title="FAQ" />

      <main className="faq_page">
        <ShapeFaq />

        <div className="container-fluid">
          <div className="page_header_wrapper">
            <h1 className="page_header" data-text="&#60;F4Q/&#62;">
              &#60;F<span>4</span>Q/&#62;
            </h1>
          </div>

          <div className="row">
            <div className="col-lg-3 col_1">
              <ul>
                <li className="active">
                  <a href="#ogolne">Ogólne</a>
                </li>
                <li>
                  <a href="#cennik-i-pakiety">Cennik i pakiety</a>
                </li>
                <li>
                  <a href="#platnosci">Płatności</a>
                </li>
                <li>
                  <a href="#panel-uzytkownika">Panel użytkownika</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-9 col_2">
              <span className="anchor" id="ogolne"></span>
              <h3>Ogólne</h3>
              {faq.faq_ogolne.map((node, index) => (
                <div
                  className={`faq_question ${index === 0 ? "open" : ""}`}
                  key={index}
                >
                  <strong>{node.faq_ogolne_header}</strong>
                  <div
                    style={
                      index === 0 ? { display: "block" } : { display: "none" }
                    }
                    dangerouslySetInnerHTML={{
                      __html: node.faq_ogolne_description,
                    }}
                  />
                </div>
              ))}

              <div className="divider"></div>
              <span className="anchor" id="cennik-i-pakiety"></span>
              <h3>Cennik i pakiety</h3>
              {faq.faq_cennik_pakiety.map((node, index) => (
                <div className="faq_question" key={index}>
                  <strong>{node.faq_cennik_pakiety_header}</strong>
                  <div
                    style={{ display: "none" }}
                    dangerouslySetInnerHTML={{
                      __html: node.faq_cennik_pakiety_description,
                    }}
                  />
                </div>
              ))}

              <div className="divider"></div>
              <span className="anchor" id="platnosci"></span>
              <h3>Płatności</h3>
              {faq.faq_platnosci.map((node, index) => (
                <div className="faq_question" key={index}>
                  <strong>{node.faq_platnosci_header}</strong>
                  <div
                    style={{ display: "none" }}
                    dangerouslySetInnerHTML={{
                      __html: node.faq_platnosci_description,
                    }}
                  />
                </div>
              ))}

              <div className="divider"></div>
              <span className="anchor" id="panel-uzytkownika"></span>
              <h3>Panel użytkownika</h3>
              {faq.faq_panel_uzytkownika.map((node, index) => (
                <div className="faq_question" key={index}>
                  <strong>{node.faq_panel_uzytkownika_header}</strong>
                  <div
                    style={{ display: "none" }}
                    dangerouslySetInnerHTML={{
                      __html: node.faq_panel_uzytkownika_description,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="page_navigation">
            <div className="row no-gutters">
              <div className="col-sm-6">
                <div className="inner">
                  <p>
                    Nie znalazłeś odpowiedzi? <br /> Skontaktuj się z nami
                  </p>
                  <Link to="/kontakt/" className="btn_custom">
                    &#60;Kontakt/&#62;
                  </Link>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="inner">
                  <p>
                    Chcesz się dowiedzieć więcej? <br /> Zajrzyj na bloga
                  </p>
                  <Link to="/blog/" className="btn_custom">
                    &#60;Blog/&#62;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        faqFields: allWordpressAcfPages(filter: { wordpress_id: { in: 81 } }) {
          edges {
            node {
              acf {
                faq_cennik_pakiety {
                  faq_cennik_pakiety_header
                  faq_cennik_pakiety_description
                }
                faq_ogolne {
                  faq_ogolne_header
                  faq_ogolne_description
                }
                faq_panel_uzytkownika {
                  faq_panel_uzytkownika_header
                  faq_panel_uzytkownika_description
                }
                faq_platnosci {
                  faq_platnosci_header
                  faq_platnosci_description
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FaqPage {...data} />}
  />
)
