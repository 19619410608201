import React from "react"
import LazyLoad from "react-lazyload"

const ShapeFaq = () => (
  <>
    <LazyLoad once>
      <img
        className="img-fluid shape faq_shape_1"
        src={require("../../assets/images/faq_shape_1.svg")}
        alt=""
      />
    </LazyLoad>
    <LazyLoad once>
      <img
        className="img-fluid shape faq_shape_2"
        src={require("../../assets/images/faq_shape_2.svg")}
        alt=""
      />
    </LazyLoad>
    <LazyLoad once>
      <img
        className="img-fluid shape faq_shape_3"
        src={require("../../assets/images/faq_shape_3.svg")}
        alt=""
      />
    </LazyLoad>
    <LazyLoad once>
      <img
        className="img-fluid shape faq_shape_4"
        src={require("../../assets/images/faq_shape_4.svg")}
        alt=""
      />
    </LazyLoad>
  </>
)

export default ShapeFaq
